import { FloatButton, Dropdown } from "antd"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { value: 'en', label: 'English', slug: 'en-US' },
  { value: 'fr', label: 'Français', slug: 'fr-FR' },
]
export const LanguageSwitcher = () => {
  const [language, setLanguage] = useState<any>();
  const { i18n } = useTranslation();
  const handleChange = (index: string) => {
    setLanguage(languages[+index]);
    i18n.changeLanguage(languages[+index].value);
  };

  useEffect(() => {
    const lang = languages.find((elt) => elt.slug === i18n.language || elt.value === i18n.language);
    setLanguage(lang);
  }, [])

  const items = getItems(languages);
  return (
    <Dropdown menu={{ items, onClick: (info) => handleChange(info.key) }} placement="bottom" trigger={['click', 'contextMenu']}>
      <FloatButton className="lang-btn" icon={<img src={`/icons/${language?.value}.png`} alt={language?.value} className="w-8 h-8" />} />
    </Dropdown>
  )
}

const getItems = (items: any[]) => {
  return items.map((item, index) => {
    return {
      key: index.toString(),
      label: (<span>{item.label}</span>)
    }
  })
}