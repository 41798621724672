import { useState } from "react";
import http from "../util/http";

const useAxiosPost = (baseUrl: string, url: string, headers?: any) => {
  const axiosIntance = http(baseUrl, headers);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const post = async (postData: any) => {
    setIsLoading(true);
    try {
      const response = await axiosIntance.post(url, postData);
      setData(response.data);
      setError(null);
    } catch (error: any) {
      setError(error.response.data);
      setData(null);
    }
    setIsLoading(false);
  };

  const axiosGet = async () => {
    setIsLoading(true);
    try {
      const response = await axiosIntance.get(url);
      setData(response.data);
      setError(null);
    } catch (error: any) {
      setError(error.response.data);
      setData(null);
    }
    setIsLoading(false);
  };
  return { data, error, isLoading, post, axiosGet };
};
export default useAxiosPost;
