import axios from "axios";
const httpInstance = (baseURL: string, headers: any) => {
  const instance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
      "api-key": process.env.REACT_APP_API_KEY,
      "client-id": process.env.REACT_APP_CLIENT_ID,
      ...headers,
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance;
};

export default httpInstance;
