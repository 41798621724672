import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Card, Spin } from "antd";
import { useTranslation } from "react-i18next";


const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 60,
    }}
    spin
  />
);

export const LoadingPage = ({ text }: any) => {
  const { t } = useTranslation()

  return (
    <div className="payment_container">
      <Card className="payment_container_card">
        <div className="loader-container">
          <Spin className="mb-50" indicator={antIcon} />
          <h1 className="bold text-dark">{t('loadingMsg')}</h1>
          <h5 className="text-gray">{text}</h5>
        </div>
      </Card>
    </div>
  );
};

