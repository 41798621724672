import { Button, Divider, Form, theme, Tooltip, notification } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxiosPost from "../hooks/useAxiosPost";
import { useEffect } from "react";
import { PaymentContainer } from "src/containers/PaymentContainer";
import { copyToclipBoard } from "src/util";
import { QRCodeDialog } from "src/dialogs/QrCodeDialog";
import { LoadingPage } from "src/components/LoadingPage";
import { routes } from "src/data/routes";
import { useTranslation } from "react-i18next";


const ConfirmPayment = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const PAYMENT_BASE_URL = process.env.REACT_APP_PAYMENT_BASE_URL || '';
  const { t } = useTranslation();

  const { useToken } = theme;
  const { state } = useLocation();
  const { token } = useToken();
  const navigate = useNavigate();
  const { trackingCode } = useParams();
  const logo = "/assets/images/ejarafav.jpeg";

  const {
    data: verifyPaymentResponse,
    error,
    isLoading,
    axiosGet: verifyPayment,
  } = useAxiosPost(PAYMENT_BASE_URL, `/payment/url/${trackingCode}/verify`);

  useEffect(() => {
    if (verifyPaymentResponse?.data && ["FAILED", "INCOMPLETE", "COMPLETE"].includes(verifyPaymentResponse?.data?.status)) {
      navigate(`/${trackingCode}/${routes.STATUS_PAYMENT}`, { state: verifyPaymentResponse },);
    }

    if (verifyPaymentResponse?.data && verifyPaymentResponse?.data?.status === "INITIATED") {
      api.info({
        message: t("paymentInfo.message"),
        description: t("paymentInfo.description"),
        placement: "topRight",
      });
    }

  }, [verifyPaymentResponse]);

  if (isLoading) {
    return (
      <LoadingPage text={t("paymentLoading")} />
    );
  }
  return (
    <PaymentContainer>
      {contextHolder}
      <div className="amount_container">
        <h5 className='text-gray'>{t("totalAmount")}</h5>
        <div className='amount_value text-dark'><h1>{(+state?.payntInitResp?.fiatAmount).toLocaleString('en-US')}</h1> <h4>{state?.payntInitResp?.fiatCurrency}</h4> <h5 className="space text-gray">{parseFloat(state?.payntInitResp?.cryptoAmount)}&nbsp;{state?.payntInitResp?.ejaraCode}</h5></div>
      </div>
      <div className="crypto_container">
        <div className="crypto_info">
          <label>{t("amountToPay")}</label>
          <h3 className="text-600 text-dark">{parseFloat(state?.paymentInitiationResponse?.totalCryptoAmount) + " " + state?.payntInitResp?.ejaraCode}</h3>
        </div>
        <div className="crypto_info">
          <label>{t("withdrawalFee")}</label>
          {state?.paymentInitiationResponse?.strike ?
            <div className="flex-start ">
              <h3 className="text-600 text-dark mr-5 ">{parseFloat(state?.paymentInitiationResponse?.cryptoFee) + " " + state?.payntInitResp?.ejaraCode}</h3>
              <h4 className="text-primary  text-600 crossed">{parseFloat(state?.paymentInitiationResponse?.cryptoFeeWithoutPromo) + " " + state?.payntInitResp?.ejaraCode}</h4>
            </div> :
            <h3 className="text-600 text-dark">{parseFloat(state?.paymentInitiationResponse?.cryptoFee) + " " + state?.payntInitResp?.ejaraCode}</h3>
          }

        </div>
        <div className="crypto_info">
          <label>{t("recipienAddress")}</label>
          <div className="crypto_address">
            <h3 className="text-600 text-dark truncate">{state?.payntInitResp?.address}</h3>
            <Tooltip placement="topLeft" trigger={['click']} title={'copied to clipboard'}>
              <button className=" text-primary" onClick={() => copyToclipBoard(state?.payntInitResp?.address || '')}>{t("copy")}</button>
            </Tooltip>
          </div>

        </div>

      </div>
      <Divider plain><label>{t("proceedToPaymentWith")}</label></Divider>

      <div className="payment-btn">
        <Button className="mr-5" onClick={() => setIsOpen(true)}>{t("scanQrCodeMsg")}</Button>
        <Button className="ml-5" disabled>{t("walletConnect")}</Button>
      </div>

      <QRCodeDialog
        errorLevel="H"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={{
          address: state?.payntInitResp?.address,
          logo: logo,
          colorInfoText: token.colorInfoText,
          colorBgLayout: token.colorBgLayout
        }}
      />
      <div className="form-group">
        {error && (
          <Form.Item>
            <p style={{ color: "red" }}>{error.message}</p>
          </Form.Item>
        )}
      </div>
      <div className="form-group">
        <Button
          size="large"
          className="primary"
          onClick={async () => {
            await verifyPayment();
          }}
        >
          {t("ivepaid")}
        </Button>
      </div>
    </PaymentContainer>
  );
};

export default ConfirmPayment;
