import { Image } from "antd";
import { useTranslation } from "react-i18next";
const PageNotFound = "/assets/images/404.svg";

export const ErrorPage = ({ text }: any) => {
    const { t } = useTranslation()
    return (
        <div className="loader-container">
            <div>
                <Image src={PageNotFound} width={350} preview={false} />
                <div className="error-content">
                    <h2>{t('errorMsg')}</h2>
                    <p>
                        {text}
                    </p>
                </div>
            </div>
        </div>
    );
}