import { Button, Card, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DangerIcon, ReceiptIcon, SuccessIcon, WarningIcon } from "src/icons";
import { copyToclipBoard } from "src/util";

const StatusResponse = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { trackingCode } = useParams();

  const status = state?.data?.status;
  const { t } = useTranslation();
  const { totalCryptoAmount, address, ejaraCode } = state?.data?.transactions[0];
  const values: any =
  {
    'COMPLETE': {
      icon: <div className="status_icon_container bg-accent-success"><SuccessIcon className='status_icon bg-success' /></div>,
      component: <>
        <div className="crypto_container w-full">
          <div className="crypto_info">
            <label>{t("totalPaid")}</label>
            <h3 className="text-600 text-dark">{parseFloat(totalCryptoAmount) + " " + ejaraCode}</h3>
          </div>
          <div className="crypto_info">
            <label>{t("recipienAddress")}</label>
            <div className="crypto_address">
              <h3 className="text-600 text-dark truncate uppercase">{address}</h3>
              <Tooltip placement="topLeft" trigger={['click']} title={'copied to clipboard'}>
                <button className=" text-primary" onClick={() => copyToclipBoard(address || '')}>Copy</button>
              </Tooltip>
            </div>

          </div>
          <div className="crypto_info">
            <label>{t("referenceNumber")}</label>
            <h3 className="text-600 text-dark truncate uppercase">{trackingCode}</h3>
          </div>

        </div>
        <Button className="ml-5 mt-10 bg-accent-gray  text-dark receip-btn" icon={<ReceiptIcon className='mr-10 receipt-icon' />}>{t("downloadReceipt")}</Button>
      </>
    },
    'INCOMPLETE': {
      icon: <div className="status_icon_container bg-accent-warning"><WarningIcon className='status_icon bg-warning' /></div>,
    },
    'FAILED': {
      icon: <div className="status_icon_container bg-accent-error"> <DangerIcon className='status_icon bg-error' /></div>,
      component: <Button className="ml-5 mt-25 bg-accent-primary text-primary receip-btn" onClick={() => navigate(`/${trackingCode}`)}>{t("retryPaymentMsg")}</Button>

    }
  }
  const { icon, component } = values[status || ''];
  return (
    <div className="payment_container">
      <Card className="payment_container_card">
        <div className="status_container">
          {icon}
          <h2 className="text-dark mt-10">{t(`status.${status.toLowerCase()}.title`)}</h2>
          <h4 className="text-gray text-center">{t(`status.${status.toLowerCase()}.message`)}</h4>
          {component}
        </div>
      </Card>
    </div>
  );
};

export default StatusResponse;
