import { Image } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PageNotFound = "/assets/images/404.svg";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="loader-container">
      <div>
        <Image src={PageNotFound} width={350} />
        <div className="error-content">
          <h2>{t('notFoundMsg.0')}</h2>
          <p>{t('notFoundMsg.1')}</p>
          <div className="btns">
            <button className="btn">{t('retryPaymentMsg')}</button>
            <button className="btn">{t('getHelpMsg')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
