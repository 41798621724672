import { ConfigProvider, ThemeConfig } from 'antd';
import React from 'react';
import { LanguageSwitcher } from 'src/components/language-switcher';
import { BaseProps } from 'src/models/props';
export const RootLayout: React.FC<BaseProps> = ({ children }) => {
    const theme: ThemeConfig = {
        token: {
            colorPrimary: '#505AE9',
            colorPrimaryBg: '#505AE9'
        }
    }
    return (
        <ConfigProvider theme={theme}>
            <div className="layout">
                {children}
                <LanguageSwitcher />
            </div>
        </ConfigProvider>
    );
}