import { Modal, QRCode, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { copyToclipBoard } from "src/util";

export const QRCodeDialog = ({ isOpen, setIsOpen, data }: any) => {

    const handleCancel = () => {
        setIsOpen(false);
    };
    const { t } = useTranslation();
    if (!data) { return <></> }
    const { address, logo, colorInfoText } = data;
    return (
        <div >
            <Modal className="modal" title={t("scanQrCodeMsg")} centered open={isOpen} footer={[]} onCancel={handleCancel}>
                <div className="qrcode_container">
                    <QRCode
                        errorLevel="H"
                        value={address}
                        icon={logo}
                        color={colorInfoText}
                        className="qrcode"
                        size={230}

                    />
                </div>
                <div className="crypto_info">
                    <label>{t("recipienAddress")}</label>
                    <div className="crypto_address">
                        <h3 className="text-600 text-dark truncate">{address}</h3>
                        <Tooltip placement="topLeft" trigger={['click']} title={t("copiedMsg")}>
                            <button className=" text-primary" onClick={() => copyToclipBoard(address || '')}>{t("copyMsg")}</button>
                        </Tooltip>
                    </div>
                </div>
            </Modal>
        </div>


    );
}