import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import InitiatePayment from "./pages/InitiatePayment";
import ConfirmPayment from "./pages/ConfirmPayment";
import StatusResponse from "./pages/StatusResponse";
import NotFound from "./pages/404";
import { routes } from "./data/routes";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={`:trackingCode`} element={
                    <Navigate to={`${routes.INITIATE_PAYMENT}`} />
                } />
                <Route path={`:trackingCode/${routes.INITIATE_PAYMENT}`} element={<InitiatePayment />} />
                <Route path={`:trackingCode/${routes.CONFIRM_PAYMENT}`} element={<ConfirmPayment />} />
                <Route path={`:trackingCode/${routes.STATUS_PAYMENT}`} element={<StatusResponse />} />
                <Route path={`/${routes.NOT_FOUND}`} element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}