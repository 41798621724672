import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'src/data/routes';
import { ChevronRightIcon } from 'src/icons';
import { BaseProps } from 'src/models/props';
export const PaymentContainer: React.FC<BaseProps> = ({ children }) => {

    const { t } = useTranslation();

    const breadcrumb = [
        { label: t('selecPaymentMethod'), path: routes.INITIATE_PAYMENT },
        { label: t('proceedToPayment'), path: routes.CONFIRM_PAYMENT }
    ];

    const path = `${window.location.pathname}`.split('/').pop() || '';

    const currentIndex = breadcrumb.findIndex((elt) => elt.path === path)


    return (
        <div className="payment_container">
            <Card className="payment_container_card">
                <h2 className='text-dark'>{t('completPaymentMsg')}</h2>
                <div className="breadcrumb">
                    {breadcrumb.map((elt, index) => {
                        const isCurrent = elt.path === path;
                        const isVisited = index < currentIndex;
                        return (
                            <div className='breadcrumb_item' key={index}>
                                {
                                    index !== 0 &&
                                    <ChevronRightIcon className={`${isCurrent ? 'text-dark' : 'text-gray'}`}></ChevronRightIcon>
                                }
                                <h5 className={`${isCurrent ? 'text-primary' : isVisited ? 'text-dark' : 'text-gray'}`}>{elt.label}</h5>
                            </div>
                        );
                    })}
                </div>
                {children}
            </Card>
        </div>
    );
}