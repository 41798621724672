import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPost from "../hooks/useAxiosPost";
import { v4 as uuidv4 } from "uuid";
import { PaymentContainer } from "src/containers/PaymentContainer";
import { routes } from "src/data/routes";
import { ErrorPage } from "src/components/ErrorPage";
import { LoadingPage } from "src/components/LoadingPage";
import { Payload } from "src/models/props";
import { ImageView } from "src/components/image-view";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const PAYMENT_BASE_URL = process.env.REACT_APP_PAYMENT_BASE_URL || '';

const InitiatePayment = () => {
  const navigate = useNavigate();
  const { trackingCode } = useParams();
  const [form] = Form.useForm();
  const [allowedCryptos, setAllowedCryptos] = useState<any>([]);
  const [cryptoType, setCryptoType] = useState<any>("");
  const [networkList, setNetworkList] = useState<any>([]);
  const [payload, setPayload] = useState<Payload>({
    ejaraCode: "",
    deviceId: "",
  });

  const { t } = useTranslation();

  const {
    data: paymentInitiationResponse,
    error: paymentIniationError,
    isLoading: initiatingPayment,
    post: initiatePayment,
  } = useAxiosPost(PAYMENT_BASE_URL, `/payment/url/${trackingCode}/initiate`);

  const {
    data: payntUrlResponse,
    error: payntUrlError,
    isLoading: fetchingPayntUrl,
    axiosGet: fetchPayntUrl,
  } = useAxiosPost(PAYMENT_BASE_URL, `/payment/url/${trackingCode}`);

  const genDevId = () => {
    localStorage.setItem("deviceId", uuidv4());
    setPayload((prev) => {
      return {
        ...prev,
        deviceId: uuidv4(),
      };
    });
  };

  useEffect(() => {
    genDevId();
    fetchPayntUrl();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paymentInitiationResponse?.data?.status) {
      navigate(`/${trackingCode}/${routes.CONFIRM_PAYMENT}`, {
        state: {
          payntInitResp: {
            description: payntUrlResponse?.data?.description,
            cryptoAmount: paymentInitiationResponse?.data?.cryptoAmount,
            fiatAmount: paymentInitiationResponse?.data?.fiatAmount,
            fiatCurrency: paymentInitiationResponse?.data?.fiatCurrency,
            ejaraCode: paymentInitiationResponse?.data?.ejaraCode,
            address: paymentInitiationResponse?.data?.address,
          },
          paymentInitiationResponse: {
            cryptoFee: paymentInitiationResponse?.data?.cryptoFee,
            cryptoFeeWithoutPromo:
              paymentInitiationResponse?.data?.cryptoFeeWithoutPromo,
            totalCryptoAmount:
              paymentInitiationResponse?.data?.totalCryptoAmount,
            feePercent: paymentInitiationResponse?.data?.feePercent,
            promoPercent: paymentInitiationResponse?.data?.promoPercent,
            strike: paymentInitiationResponse?.data?.strike,

          },
        },
      });
    }
    // eslint-disable-next-line
  }, [paymentInitiationResponse]);

  useEffect(() => {
    setAllowedCryptos(
      () =>
        payntUrlResponse?.data &&
        payntUrlResponse.data.allowedCryptos.tokens.map((item: any) => {
          return {
            value: item.code,
            label: item.name,
            type: item.type,
            icon: item.icon,
          };
        })
    );
  }, [payntUrlResponse]);

  useEffect(() => {
    setNetworkList(
      payntUrlResponse?.data?.allowedCryptos.networks
        .filter((item: any) => item.type === cryptoType.typeCode)
        .map((item: any) => {
          return {
            value: item.code,
            label: item.name,
            icon: item.icon,
          };
        })
    );
    // eslint-disable-next-line
  }, [payload]);

  if (fetchingPayntUrl) {
    return (
      <LoadingPage text='' />
    );
  }

  if (payntUrlError) {
    return (
      <ErrorPage text={t('fetchPaymentErrorMsg')} />

    );
  }

  if (!["PENDING", "INITIATED", "INCOMPLETE"].includes(payntUrlResponse?.data.status)) {
    return (
      <ErrorPage text={t("errorPaymentErrorMsg")} />
    );
  }

  return (
    <PaymentContainer>
      <div className="amount_container">
        <h5 className='text-gray'>{t("amountToPay")}</h5>
        <div className='amount_value text-dark'><h1>{(+payntUrlResponse?.data?.fiatAmount).toLocaleString('en-US')}</h1> <h4>{payntUrlResponse?.data?.fiatCurrency}</h4>   </div>
      </div>

      <Form
        name="payment-initiation-form"
        layout="vertical"
        form={form}
        onFinish={(values) => {
          if (!payload.email) { delete payload.email }
          initiatePayment({ ...payload });
        }}
        initialValues={{
          fiatCurrency:
            payntUrlResponse?.data?.fiatCurrency +
            " " +
            payntUrlResponse?.data?.fiatAmount,
        }}
        requiredMark={false}
      >
        <Form.Item
          name="ejaraCode"
          label={t("form.crypto")}
          rules={[{ required: true, message: "" }]}
        >
          <Select
            size="large"
            style={{
              width: "100%",
            }}
            onChange={(value) => {
              form.resetFields(["ejaraCode2"]);
              setPayload((prev) => {
                return {
                  ...prev,
                  ejaraCode: value,
                };
              });
              setCryptoType(() => {
                const ctype = allowedCryptos?.find(
                  (item: any) => item.value === value
                );
                return { ctype: ctype.type, typeCode: ctype.value };
              });
            }}
          >
            {allowedCryptos?.map((item: any, i: number) => (
              <Option key={i} value={item.value}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={item.icon}
                    alt="icon"
                    width={15}
                    style={{ marginRight: 7 }}
                  />
                  <span style={{ alignSelf: "center" }}>{item.label}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="email"
          label={t("form.email")}
          style={{ marginTop: "1rem" }}
          rules={[{ required: false, message: "" }]}
        >
          <Input size="large" value={payntUrlResponse?.data?.email} name="email" onChange={(event) => {
            setPayload((prev) => {
              return {
                ...prev,
                email: event?.target.value,
              };
            });
          }} />
        </Form.Item>
        {cryptoType?.ctype === "derived" && (
          <Form.Item
            name="ejaraCode2"
            label={t("form.network")}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              size="large"
              style={{
                width: "100%",
              }}
              onChange={(value) =>
                setPayload((prev) => {
                  return {
                    ...prev,
                    ejaraCode: value,
                  };
                })
              }
            >
                // <Option value="">{t("form.network")} </Option>
              {networkList?.map((item: any, i: number) => (
                <Option key={i} value={item.value}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.icon}
                      alt="icon"
                      width={15}
                      style={{ marginRight: 7 }}
                    />
                    <span style={{ alignSelf: "center" }}>{item.label}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {payntUrlResponse?.data?.imageUrls && (
          <ImageView imageUrls={payntUrlResponse?.data?.imageUrls} />
        )}
        {paymentIniationError && (
          <Form.Item>
            <p style={{ color: "red" }}>{paymentIniationError.message}</p>
          </Form.Item>
        )}
        <Form.Item>
          <Button
            htmlType="submit"
            size="large"
            loading={initiatingPayment}
            className="primary"
          >
            {t("continueBtn")}
          </Button>
        </Form.Item>
      </Form>
    </PaymentContainer>

  );
};

export default InitiatePayment;
