
export const ChevronRightIcon = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clipRule="evenodd" />
    </svg>)
    ;
}

export const ChevronDownIcon = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
    </svg>
  );
}

export const DangerIcon = ({ className }: any) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="currentColor" className={className}>
    <path d="M24.9993 45.8334C36.5053 45.8334 45.8327 36.506 45.8327 25.0001C45.8327 13.4941 36.5053 4.16675 24.9993 4.16675C13.4934 4.16675 4.16602 13.4941 4.16602 25.0001C4.16602 36.506 13.4934 45.8334 24.9993 45.8334Z" fill="#FF1D15" />
    <path d="M25 28.6459C25.8542 28.6459 26.5625 27.9376 26.5625 27.0834V16.6667C26.5625 15.8126 25.8542 15.1042 25 15.1042C24.1458 15.1042 23.4375 15.8126 23.4375 16.6667V27.0834C23.4375 27.9376 24.1458 28.6459 25 28.6459Z" fill="#FFF4F3" />
    <path d="M26.916 32.5417C26.8119 32.2917 26.666 32.0626 26.4785 31.8542C26.2702 31.6667 26.041 31.5209 25.791 31.4167C25.291 31.2084 24.7077 31.2084 24.2077 31.4167C23.9577 31.5209 23.7285 31.6667 23.5202 31.8542C23.3327 32.0626 23.1868 32.2917 23.0827 32.5417C22.9785 32.7917 22.916 33.0626 22.916 33.3334C22.916 33.6042 22.9785 33.8751 23.0827 34.1251C23.1868 34.3959 23.3327 34.6042 23.5202 34.8126C23.7285 35.0001 23.9577 35.1459 24.2077 35.2501C24.4577 35.3542 24.7285 35.4167 24.9993 35.4167C25.2702 35.4167 25.541 35.3542 25.791 35.2501C26.041 35.1459 26.2702 35.0001 26.4785 34.8126C26.666 34.6042 26.8119 34.3959 26.916 34.1251C27.0202 33.8751 27.0827 33.6042 27.0827 33.3334C27.0827 33.0626 27.0202 32.7917 26.916 32.5417Z" fill="#FFF4F3" />
  </svg>
  );
}

export const SuccessIcon = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="currentColor" className={className}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0512 13.7827C30.5394 14.2709 30.5394 15.0624 30.0512 15.5505L18.3845 27.2172C17.8964 27.7053 17.1049 27.7053 16.6168 27.2172L9.9501 20.5505C9.46195 20.0624 9.46195 19.2709 9.9501 18.7827C10.4383 18.2946 11.2297 18.2946 11.7179 18.7827L17.5007 24.5655L28.2834 13.7827C28.7716 13.2946 29.563 13.2946 30.0512 13.7827Z" fill="#F3FCF6" />
    </svg>

  );
}



export const WarningIcon = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" className={className}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>



  );
}

export const ReceiptIcon = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" className={className}>
      <path d="M8.00065 15.1666C7.48065 15.1666 6.97398 14.8999 6.62732 14.4333L5.95398 13.5333C5.81398 13.3466 5.62732 13.2399 5.42732 13.2266C5.22732 13.2199 5.02732 13.3066 4.86732 13.4733L4.48732 13.1333L4.85398 13.4733C3.89398 14.4999 3.15398 14.4199 2.80065 14.2799C2.44065 14.1399 1.83398 13.6799 1.83398 12.1999V4.69325C1.83398 1.73325 2.68732 0.833252 5.48065 0.833252H10.5207C13.314 0.833252 14.1673 1.73325 14.1673 4.69325V12.1999C14.1673 13.6733 13.5607 14.1333 13.2007 14.2799C12.8473 14.4199 12.114 14.4999 11.1473 13.4733C10.9873 13.2999 10.7873 13.2066 10.5807 13.2266C10.3807 13.2399 10.1873 13.3466 10.0473 13.5333L9.37398 14.4333C9.02732 14.8999 8.52065 15.1666 8.00065 15.1666ZM5.38732 12.2199C5.41398 12.2199 5.44732 12.2199 5.47398 12.2199C5.96732 12.2466 6.43398 12.5066 6.74732 12.9266L7.42065 13.8266C7.74732 14.2599 8.24732 14.2599 8.57398 13.8266L9.24732 12.9266C9.56732 12.5066 10.0273 12.2466 10.5273 12.2199C11.0207 12.1933 11.514 12.3999 11.874 12.7866C12.3807 13.3266 12.7073 13.3933 12.8273 13.3466C12.9873 13.2799 13.1607 12.8933 13.1607 12.1999V4.69325C13.1607 2.28659 12.7407 1.83325 10.514 1.83325H5.48065C3.25398 1.83325 2.83398 2.28659 2.83398 4.69325V12.1999C2.83398 12.8999 3.00732 13.2866 3.16732 13.3466C3.28065 13.3933 3.61398 13.3266 4.12065 12.7866C4.48065 12.4199 4.92732 12.2199 5.38732 12.2199Z" fill="#3F4481" />
      <path d="M10.821 7.83325H7.1543C6.88096 7.83325 6.6543 7.60659 6.6543 7.33325C6.6543 7.05992 6.88096 6.83325 7.1543 6.83325H10.821C11.0943 6.83325 11.321 7.05992 11.321 7.33325C11.321 7.60659 11.0943 7.83325 10.821 7.83325Z" fill="#3F4481" />
      <path d="M10.821 5.16675H7.1543C6.88096 5.16675 6.6543 4.94008 6.6543 4.66675C6.6543 4.39341 6.88096 4.16675 7.1543 4.16675H10.821C11.0943 4.16675 11.321 4.39341 11.321 4.66675C11.321 4.94008 11.0943 5.16675 10.821 5.16675Z" fill="#3F4481" />
      <path d="M5.1862 5.33333C4.81953 5.33333 4.51953 5.03333 4.51953 4.66667C4.51953 4.3 4.81953 4 5.1862 4C5.55286 4 5.85286 4.3 5.85286 4.66667C5.85286 5.03333 5.55286 5.33333 5.1862 5.33333Z" fill="#3F4481" />
      <path d="M5.1862 8.00008C4.81953 8.00008 4.51953 7.70008 4.51953 7.33341C4.51953 6.96675 4.81953 6.66675 5.1862 6.66675C5.55286 6.66675 5.85286 6.96675 5.85286 7.33341C5.85286 7.70008 5.55286 8.00008 5.1862 8.00008Z" fill="#3F4481" />
    </svg>
  );
}

