import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";

const i18n = createInstance({
  fallbackLng: 'en',
  ns:['common'],
  defaultNS: 'common',
  debug: process.env.NODE_ENV === 'development',
  detection: {
    order: ["localStorage", "cookie"],
    caches: ["localStorage", "cookie"],
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  }
});


i18n.use(Backend).use(LanguageDetector).init();

export default i18n;