import React from "react";
import Router from "./router";
import { RootLayout } from "./containers/RootLayout";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <RootLayout>
        <Router />
      </RootLayout>
    </I18nextProvider>
  );
};

;
